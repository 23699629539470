import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Природний Рай
			</title>
			<meta name={"description"} content={" Наш затишний готель, розташований серед мальовничих краєвидів, пропонує вам спокійний відпочинок, який поєднує в собі йогу, спа-процедури та вегетаріанську кухню."} />
			<meta property={"og:title"} content={"Природний Рай"} />
			<meta property={"og:description"} content={" Наш затишний готель, розташований серед мальовничих краєвидів, пропонує вам спокійний відпочинок, який поєднує в собі йогу, спа-процедури та вегетаріанську кухню."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text2" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header23>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Про нас
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Місія Природного Раю – створити ідеальне місце для відпочинку та відновлення. Ми прагнемо надати нашим гостям унікальний досвід, який поєднує оздоровлення тіла та душі.
					<br />
					<br />
					Природний Рай заснований на ідеї гармонії з природою. Ми почали свій шлях з маленького проекту, який зріс до місця, де гості з усього світу можуть знайти спокій та натхнення.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/DSC_6008.jpg?v=2024-06-10T11:16:53.840Z"
					width="100%"
					max-height="auto"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/DSC_6008.jpg?v=2024-06-10T11%3A16%3A53.840Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/DSC_6008.jpg?v=2024-06-10T11%3A16%3A53.840Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/DSC_6008.jpg?v=2024-06-10T11%3A16%3A53.840Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/DSC_6008.jpg?v=2024-06-10T11%3A16%3A53.840Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/DSC_6008.jpg?v=2024-06-10T11%3A16%3A53.840Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/DSC_6008.jpg?v=2024-06-10T11%3A16%3A53.840Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/DSC_6008.jpg?v=2024-06-10T11%3A16%3A53.840Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="24px 0 60px 0" md-padding="30px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				md-margin="0px 0px 30px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				md-display="flex"
			>
				<Image
					height="auto"
					src="https://images.unsplash.com/photo-1589790272257-8c0f79c221da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					lg-width="320px"
					lg-height="320px"
					width="100%"
					min-width="500px"
					srcSet="https://images.unsplash.com/photo-1589790272257-8c0f79c221da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1589790272257-8c0f79c221da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1589790272257-8c0f79c221da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1589790272257-8c0f79c221da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1589790272257-8c0f79c221da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1589790272257-8c0f79c221da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1589790272257-8c0f79c221da?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="auto"
				display="flex"
				justify-content="center"
				md-width="100%"
				align-items="flex-end"
			>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px" justify-content="flex-end">
					<Box display="flex" align-items="center" width="auto" />
					<Box display="flex" align-items="center" width="60%">
						<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif">
							<br />
							Ми цінуємо екологічність, здоров'я та гармонію. Наші цінності відображаються в кожному аспекті нашого готелю – від використання органічних продуктів до створення комфортних умов для відпочинку.
						</Text>
					</Box>
				</Box>
				<Box align-items="center" margin="0px 0px 16px 0px" display="flex" justify-content="flex-end">
					<Box display="flex" align-items="center" width="auto" />
					<Box display="flex" align-items="center" width="60%" />
				</Box>
				<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
					<Box display="flex" align-items="center" width="60%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" md-padding="20px 0 12px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					max-height="400px"
					width="100%"
					srcSet="https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1524863479829-916d8e77f114?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					margin="40px 0px 0px 0px"
					lg-width="70%"
					md-width="100%"
				>
					<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
						Пропонуємо різноманітні йога-програми, які підходять для новачків і досвідчених практиків. Наші заняття допоможуть вам знайти внутрішній спокій та покращити фізичну форму.
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://images.unsplash.com/photo-1510894347713-fc3ed6fdf539?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://images.unsplash.com/photo-1510894347713-fc3ed6fdf539?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1510894347713-fc3ed6fdf539?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1510894347713-fc3ed6fdf539?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1510894347713-fc3ed6fdf539?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1510894347713-fc3ed6fdf539?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1510894347713-fc3ed6fdf539?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1510894347713-fc3ed6fdf539?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					margin="40px 0px 0px 0px"
					lg-width="70%"
					md-width="100%"
				>
					<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
						Приділіть час для медитації та релаксації. Наші спеціальні зони для медитації допоможуть вам знайти спокій та відновити енергію.
					</Text>
					<Button
						background="--color-green"
						border-radius="13px"
						min-width="200px"
						max-width="100%"
						margin="0px auto 0px auto"
						text-align="center"
						type="link"
						text-decoration-line="initial"
						href="/menu"
					>
						ПОСЛУГИ
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer1 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});